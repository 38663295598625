'use client'

import * as React from 'react'
import { cn } from '@/lib/utils'
import { CopyIcon } from 'lucide-react'
import { useToast } from '@/components/ui/useToast'
import copy from 'copy-to-clipboard'
import { Button } from './Button'
import { Tooltip } from './Tooltip'
import { getFormattedMoney } from '@core/util/string'
import { Label } from './Label'
import slugify from 'slugify'

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'> {
  label?: string
  error?: boolean
  enableCopy?: boolean
  copyButtonClassName?: string
  copyIconClassName?: string
  format?: 'money' | 'slugify' | 'digits'
  classes?: {
    root?: string
    input?: string
  }
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      classes,
      type,
      label,
      error,
      enableCopy,
      format,
      copyButtonClassName,
      copyIconClassName,
      onChange,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const { toast } = useToast()

    const handleCopyClick = () => {
      if (props.value) {
        copy(props.value.toString())
        toast({
          description: 'Value copied to clipboard',
          variant: 'success',
        })
      }
    }

    const formatIfNeeded = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (format === 'money') {
        e.target.value = getFormattedMoney(e.target.value)
      }
      if (format === 'slugify') {
        e.target.value = slugify(e.target.value, { lower: true, strict: true })
      }
      if (format === 'digits') {
        e.target.value = e.target.value.replace(/\D/g, '')
      }

      onChange?.(e)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (format === 'money') {
        if (!e.target.value.match?.(/\d/g)) {
          e.target.value = ''
          onChange?.(e)
          onBlur?.(e)
        }
      }
    }

    return (
      <div className={cn('flex flex-col gap-2 justify-between', classes?.root)}>
        {(label || enableCopy) && (
          <div className="flex items-center relative max-h-[14px]">
            {label && <Label>{label}</Label>}
            {enableCopy && (
              <Tooltip content="Copy to clipboard">
                <Button
                  type="button"
                  onClick={handleCopyClick}
                  className={cn('ml-2 h-[14px] px-1.5 py-2.5', copyButtonClassName)}
                  variant="outline"
                >
                  <CopyIcon className={cn('w-3 h-3', copyIconClassName)} />
                </Button>
              </Tooltip>
            )}
          </div>
        )}
        <input
          type={type}
          className={cn(
            'flex h-9 w-full rounded-md border border-slate-200 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300',
            { 'border-red-500 dark:border-red-900': error },
            classes?.input,
          )}
          ref={ref}
          onChange={formatIfNeeded}
          onBlur={handleBlur}
          {...props}
        />
      </div>
    )
  },
)
Input.displayName = 'Input'

export { Input }
